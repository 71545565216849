<template>
        <v-row class="bs-navbar mx-0 pa-2 px-8 align-center">
        <div class="bs-navbar-logo1">
            <img src="@/assets/brandings/blendedsense_x.png" class="nav-bs-logo" />
        </div>
         <div class="bs-navbar-logo2">
            <img src="@/assets/brandings/favicon.svg" class="nav-bs-logo" />
        </div>
        <v-spacer></v-spacer>
        <div>
        <div class="already-mrg" v-if="!showProfile">
              <p class="acct">
              <router-link :to="{ name: 'Login'}" class="login">Login</router-link>
            </p>
            </div>
        </div>
        <div v-if="showProfile">
            <v-menu
                left
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <div
                        class="user-avatar"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img :src="userAvatar" alt="user avatar">
                    </div>
                </template>
                <v-list class="py-0">
                    <v-list-item
                        class="cursor px-4" style="min-height: 1.5rem;"
                        @click="logout"
                    >
                        <v-list-item-title class="py-2 px-8">Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import userAvatar from '@/assets/svg/theme/user-outline-2.svg';
import { getOptimizedS3ImageURL } from '@/helpers';

export default {
  name: 'OnboardingNavBar',
  computed: {
    ...mapGetters('user', ['userDetails']),
    userAvatar() {
      // eslint-disable-next-line
      return getOptimizedS3ImageURL(this.userDetails.profilePic, { width: 48, height: 48 }) || userAvatar;
    },
  },
  props: {
    showProfile: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions('user', ['logoutUser']),
    getOptimizedS3ImageURL,
    logout() {
      this.logoutUser();
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.acct {
  margin-bottom : 0;
  display: none;
}
.login {
  color: #8066FF;
}
 .bs-navbar-logo2 {
    display: none;
  }
  .bs-navbar-logo1 {
    display: block;
  }
.bs-navbar {
    border-bottom: 1px solid #DEDEDE;
    background: white;
    .user-avatar{
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
    }
}
.cursor {
    cursor: pointer;
}
@media screen and (min-width: 0px) and (max-width: 800px)  {
  .bs-navbar-logo2 {
    margin-left:-26px;
    margin-top: 6px;
    display: block;
  }
  .bs-navbar-logo1 {
    display: none;
  }
  .acct {
  margin-bottom : 0;
  display: block;
  }
  .user-avatar{
    margin-right: -25px !important;
    min-width:25px;
    min-width: 25px;
  }
}
</style>
