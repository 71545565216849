import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"bs-navbar mx-0 pa-2 px-8 align-center"},[_c('div',{staticClass:"bs-navbar-logo1"},[_c('img',{staticClass:"nav-bs-logo",attrs:{"src":require("@/assets/brandings/blendedsense_x.png")}})]),_c('div',{staticClass:"bs-navbar-logo2"},[_c('img',{staticClass:"nav-bs-logo",attrs:{"src":require("@/assets/brandings/favicon.svg")}})]),_c(VSpacer),_c('div',[(!_vm.showProfile)?_c('div',{staticClass:"already-mrg"},[_c('p',{staticClass:"acct"},[_c('router-link',{staticClass:"login",attrs:{"to":{ name: 'Login'}}},[_vm._v("Login")])],1)]):_vm._e()]),(_vm.showProfile)?_c('div',[_c(VMenu,{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"user-avatar"},'div',attrs,false),on),[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"user avatar"}})])]}}],null,false,2541248681)},[_c(VList,{staticClass:"py-0"},[_c(VListItem,{staticClass:"cursor px-4",staticStyle:{"min-height":"1.5rem"},on:{"click":_vm.logout}},[_c(VListItemTitle,{staticClass:"py-2 px-8"},[_vm._v("Logout")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }