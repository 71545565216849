<template>
    <!-- eslint-disable -->
    <v-container fluid class="bs-container pa-0">
        <OnboardingNavBar/>
        <div class="main">
            <div class="nav-header">
                <div class="nav-header-icon backIcon">
                    <div class="nav-icon" v-on:click="back">
                        <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                    </div>
                </div>
                <div class="nav-header-content">
                    <div class="text">
                        <div class="legend">Talent Details</div>
                        <div class="subtext mt-1">
                            Tell us about your yourself and your talent capabilities. 
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-content">
                <div class="general-details-section">
                    <div class="section-title"></div>
                    <div class="section-content">
                        <div class="input-section mt-4">
                            <div class="section-input-title">Gender<sup>*</sup></div>
                            <v-select
                                v-model="gender"
                                :items="genders"
                                outlined
                                hide-details
                                class="gender-select"
                                placeholder="Select..."
                                item-text="name"
                                append-icon="mdi-chevron-down"
                                item-value="id"
                                :error-messages="errors.gender"
                            />
                            <div class="v-text-field__details" v-if="errors.gender">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">gender is required</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="input-section mt-4">
                            <div class="section-input-title">Ethnicity<sup>*</sup></div>
                            <v-select
                                v-model="ethnicity"
                                :items="ethnicities"
                                outlined
                                hide-details
                                class="ethnicity-select"
                                placeholder="Select..."
                                append-icon="mdi-chevron-down"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors.ethnicity"
                            />
                            <div class="v-text-field__details" v-if="errors.ethnicity">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">ethnicity is required</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="proficient-details-section mt-8">
                    <div class="section-title">Select what you’re proficient in being talent for:<sup>*</sup></div>
                    <div class="proficiency-list">
                            <v-checkbox
                                v-model="proficiencies"
                                label="Live Events"
                                value="Live Events"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Live Events</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Lifestyle"
                                value="Lifestyle"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Lifestyle</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Real Estate"
                                value="Real Estate"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Real Estate</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Interviews"
                                value="Interviews"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Interviews</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Narrative"
                                value="Narrative"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Narrative</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Commercial"
                                value="Commercial"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Commercial</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Cinematic"
                                value="Cinematic"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Cinematic</span>
                             </template>
                            </v-checkbox>
                             <div class="v-text-field__details" v-if="errors.proficiencies">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">proficiencies is required</div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                      <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submit"
                        >
                            Continue
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'SweepDetails',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      errors: {},
      proficiencies: [],
      ethnicity: '',
      gender: '',
      genders: [
        {
          id: '1',
          name: 'Male',
        },
        {
          id: '2',
          name: 'Female',
        },
        {
          id: '3',
          name: 'Non-Binary',
        },
        {
          id: '4',
          name: 'Other',
        },
      ],
      ethnicities: [
        {
          id: '1',
          name: 'Caucasian',
        },
        {
          id: '2',
          name: 'African-American',
        },
        {
          id: '3',
          name: 'Latino or Hispanic',
        },
        {
          id: '4',
          name: 'Asian',
        },
        {
          id: '5',
          name: 'Native American',
        },
        {
          id: '6',
          name: 'Native Hawaiian or Pacific Islander',
        },
        {
          id: '7',
          name: 'Two or More',
        },
        {
          id: '8',
          name: 'Other/Unknown',
        },
        {
          id: '9',
          name: 'Prefer not to say',
        },
      ],
    });
  },
  methods: {
    ...mapActions('onboarding', ['saveTalentPreferences']),
    ...mapActions('profile', ['getSavedProfile']),
    isValidState() {
      const errors = {};
      if (!this.gender) {
        errors.gender = 'gender is required';
      } else {
        delete errors.gender;
      }
      if (!this.ethnicity) {
        errors.ethnicity = 'ethnicity is required';
      } else {
        delete errors.ethnicity;
      }
      if (!this.proficiencies.length) {
        errors.proficiencies = 'proficiency is required';
      } else {
        delete errors.proficiencies;
      }
      this.errors = { ...errors };
      return Object.values(this.errors).length === 0;
    },
    async submit() {
      const isValid = this.isValidState();
      // generate payload
      if (!isValid) return;
      const formData = {};
      formData.gender = this.gender;
      formData.proficiencies = this.proficiencies;
      formData.ethnicity = this.ethnicity;
      this.loading = true;
      const response = await this.saveTalentPreferences(formData);
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
    back() {
      const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.skillsets, 'name');
      if (skillsets.some((r) => ['photographer', 'videographer'].includes(r.toLowerCase()))) {
        this.$router.push({
          path: '/onboarding/creative/sweep-details',
        });
      } else {
        this.$router.push({
          path: '/onboarding/creative/language-preference',
        });
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  async mounted() {
    await this.getSavedProfile();
    // eslint-disable-next-line
    const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.payTiers, ( skill )=> {
      if (skill.skillSetName === 'Talent') {
        return skill.payTier;
      }
      return skill.skillSetName;
    });
    if (Number(this.userDetails.onboardingState) < 5 || !skillsets.some((r) => ['actor', 'model'].includes(r.toLowerCase()))) {
      this.$router.push({
        path: '/',
      });
    }
    this.gender = _.get(this.userDetails, 'gender', '');
    this.ethnicity = _.get(this.userDetails, 'profile.ethnicity', '');
    this.proficiencies = _.get(this.userDetails, 'profile.talentProficient', '').split(';');
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input .v-label{
  max-width:90px !important;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1rem;
}
.nav-icon-styles{
  margin-top:18px;
}
.nav-header-content{
  margin-top:15px;
  margin-left:10px;
  margin-bottom: 10px;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.main-content {
    margin-left: 5.8rem;
}
.section-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0rem;
}
.section-input-title{
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.equipment-col {
    max-width: 450px;
}
.proficiency-checkbox {
    margin-bottom: 1rem !important;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.gender-select {
    max-width: 200px;
    margin-top: 0.3rem;
}
.ethnicity-select {
    max-width: 350px;
    margin-top: 0.3rem;
}
.backIcon {
  cursor: pointer;
}
.checkboxLabel {
  color: #262626;
}
.divider{
  width:100%;
  margin-bottom:1rem;
  border-top: 1px solid #DEDEDE;
}
@media screen and (max-width: 600px) {
  .content-main {
   padding-left: 0px;
  }
  .main {
    padding: 2rem;
    width: 100%;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .main-content {
    margin-left: 2.3rem
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left:0rem;
  }
  .continue-btn{
    width:335px;
    margin-left:0rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .nav-icon-styles{
    width:10px;
    height: 16px;
    margin-top:5px;
  }
  .bs-divider {
    margin: 1rem 0rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider{
    display: none;
  }
}
</style>
